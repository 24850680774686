@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Open Sans';
  src: url(./assets/fonts/OpenSans-Regular.ttf);
}

@font-face {
  font-family: 'Lato';
  src: url(./assets/fonts/Lato-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Poppins';
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Nunito';
  src: url(./assets/fonts/Nunito-Regular.ttf);
}

@font-face {
  font-family: 'Oxygen';
  src: url(./assets/fonts/Oxygen-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Raleway';
  src: url(./assets/fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: 'Oswald';
  src: url(./assets/fonts/Oswald-Regular.ttf);
}

@font-face {
  font-family: 'Reem Kufi Fun';
  src: url(./assets/fonts/ReemKufiFun-Regular.ttf);
}

@font-face {
  font-family: 'Vag Rounded';
  src: url(./assets/fonts/VAG-Rounded-Light.ttf);
}

body {
  // Montserrat
  font-family: 'Montserrat', sans-serif !important;
}

.modal-custom-size {
  --bs-modal-width: 80% !important;
}

.spinner-section {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 100;

  .spinnerMid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
  }
}

.button-primary-dashboard {
  max-width: fit-content;
  background-color: #0E2359;
  padding: 0.3rem 2rem;
  border-radius: 35px;
  color: #FFFFFF;
  font-size: 1.2rem;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out;
  border: none;
  display: flex; 
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  svg {
    fill: #FFFFFF;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }

  &:hover {
    background-color: #7F5CA5;
  }

  &:disabled {
    background-color: rgba(14, 35, 89, 0.80);
    color: rgb(165, 165, 165);
    cursor: not-allowed;
    pointer-events: none;

    svg {
      fill: rgb(165, 165, 165);
    }
  }
}

.button-secondary {
  max-width: fit-content;
  background-color: #ffffff;
  padding: 0.8rem 2rem;
  border-radius: 35px;
  color: #0E2359;
  font-size: 22px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border: none;

  a {
    color: #ffffff;
    text-decoration: none;
  }

  &:hover {
    background-color: #7F5CA5;
    color: #FFFFFF;
  }
}


.btn-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-tooltip:hover>.tooltip-custom {
  transform: scale(1);
}

.tooltip-custom {
  display: block;
  position: absolute;
  color: #dddddd;
  background: #18191c;
  padding: 0.4rem;
  border-radius: 3px;
  max-width: 150px;
  width: max-content;
  font-size: 0.9rem;
  transform: scale(0);
  transition: 0.055s ease-in-out transform;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
}

.tooltip-up-custom {
  bottom: 58px;
}

.tooltip-up-custom::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #18191c;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#0f0f3a, #5656a2);
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.modal-content {
  border-radius: 12px !important;
}

.card-header {
  padding: 0rem !important;
  margin-bottom: 0 !important;
  background-color: transparent !important;
  border-bottom: 1px solid rgba(0, 0, 0, .125) !important;
}

// New Plans styles
.plan {
  height: auto;
  min-height: 26rem;
  width: 20rem;
  padding-bottom: 2rem;
}

.pBasic {
  background-color: #FFFFFF;
  border-radius: 1.2rem;
  border: 1px solid #8181C2;
}

.pAdvanced {
  background-color: #FFFFFF;
  border-radius: 1.2rem;
  border: 1px solid #5656A2;
}

.pEnterprise {
  background-color: #FFFFFF;
  border-radius: 1.2rem;
  border: 1px solid #3A4192;
}

.pCustom {
  background-color: #FFFFFF;
  border-radius: 1.2rem;
  border: 1px solid #0E2359;
}

.titlePlans {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  border-radius: 1rem 1rem 0rem 0rem;
  color: #FFFFFF;
  text-align: center;
  font-weight: bold;
  font-size: 23px;
  margin-bottom: 2rem;
  z-index: 1;
}

.titlePlanBasic {
  background: linear-gradient(90deg, #020208 0%, #1C1B6D 50%, #3432C8 100%);
}

.titlePlanAdvanced {
  background: linear-gradient(90deg, #020208 0%, #1C1B6D 50%, #3432C8 100%);
}

.titlePlanEnterprise {
  background: linear-gradient(90deg, #020208 0%, #1C1B6D 50%, #3432C8 100%);
}

.titlePlanCustom {
  background: linear-gradient(90deg, #020208 0%, #1C1B6D 50%, #3432C8 100%);
}

.button-basic-outline {
  background-color: #ffffff;
  padding: 0.3rem 2rem;
  border-radius: 35px;
  color: #8181C2;
  font-size: 22px;
  font-weight: 600;
  border: 2px solid #8181C2;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  a {
    color: #8181C2;
    text-decoration: none;
  }

  &:hover {
    background-color: #8181C2;
    color: #ffffff;
  }
}

.button-advanced-outline {
  background-color: #ffffff;
  padding: 0.3rem 2rem;
  border-radius: 35px;
  color: #5656A2;
  font-size: 22px;
  font-weight: 600;
  border: 2px solid #5656A2;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  a {
    color: #5656A2;
    text-decoration: none;
  }

  &:hover {
    background-color: #5656A2;
    color: #ffffff;
  }
}

.button-enterprise-outline {
  background-color: #ffffff;
  padding: 0.3rem 2rem;
  border-radius: 35px;
  color: #3A4192;
  font-size: 22px;
  font-weight: 600;
  border: 2px solid #3A4192;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  a {
    color: #3A4192;
    text-decoration: none;
  }

  &:hover {
    background-color: #3A4192;
    color: #ffffff;
  }
}

.button-custom-outline {
  background-color: #ffffff;
  padding: 0.3rem 2rem;
  border-radius: 35px;
  color: #0E2359;
  font-size: 22px;
  font-weight: 600;
  border: 2px solid #0E2359;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;
  cursor: pointer;

  a {
    color: #0E2359;
    text-decoration: none;
  }

  &:hover {
    background-color: #0E2359;
    color: #ffffff;
  }

}

.info-plan {
  text-align: left;
  padding: 1rem 0rem;
  font-size: 16px;

  p::before {
    content: "✓ ";
    color: #150E59;
    font-size: 1.2em;
  }
}

.selectPlan {
  width: 90%;
  display: block;
  background-color: #9AD139;
  border-radius: 0.5rem;
  color: #FFFFFF;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s ease-in;
  margin: 0 auto;

  &:hover {
    background-color: #5a5a5a;
    color: #ffffff;
  }
}

.pricePlan {
  font-size: 22px;
  color: #706F6F;
}

.planSelected {
  border: 3px solid rgb(9, 9, 9);
}

// New Plans styles

//Dashboard global styles
.title-section {
  background: #F1F1F1;
  padding: 0.5rem 5rem;
  font-size: 26px;
  font-weight: 700;
  color: #150e59;

  > span {
    padding: 0.65rem 1rem;
  }

  .back-button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    gap: 2px;
    background-color: #150E59;
    border-radius: 35px;
    border: none;
    color: #FFFFFF;
    fill: white;
    padding: 8px 25px 8px 25px;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;

    svg {
      width: 30px;
      height: 30px;
    }

    &:hover {
      background-color: #7F5CA5;
      box-shadow: 0 0.20rem 0.80rem rgb(0 0 0 / 20%);
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem 2rem;
  }
}

.container-dashboard {
  display: block;
  margin: 0 auto;
  width: 90%;
  color: #3C3F3C;
  fill: #3C3F3C;
  font-size: 20px;
  max-width: 1920px;
  padding: 2rem 0rem;
}

.arrow {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0px;
  border-top: solid 3px;
  border-right: solid 3px;
}

.arrow.right {
  transform: rotate(45deg);
}

.arrow.bottom {
  transform: rotate(135deg);
}

.arrow.left {
  transform: rotate(225deg);
}

.arrow.up {
  transform: rotate(315deg);
}

.p-accordion-header-text {
  line-height: 1;
  font-weight: bold;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f1f1f1 !important;
  text-decoration: none;
  color: #495057;
}

.p-accordion-header-link {
  text-decoration: none;
  color: #495057;
}

.p-tabview .p-tabview-nav-content > ul > li > a {
  color: #495057;
  text-decoration: none;
}

.p-dropdown-items {
  padding-left: 0rem;
  margin-bottom: 0rem;
}

// Nuevo carousel
.p-carousel-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-carousel-items-content {
  overflow: hidden;
  width: 100%;
  // padding: 0rem 2rem 0rem 2rem;
}

.p-carousel-items-container {
  display: flex;
  gap: 10px;
  flex-direction: row;
}

.p-carousel .p-carousel-content .p-carousel-container .p-icon {
  width: 3rem;
  height: 3rem;
}

@media (max-width: 900px) {
  .p-carousel-content {
    display: flex;
    align-items: unset;
    justify-content: unset;
  }
}

// Swal custom styles for buttons
.swalConfirmBtn {
  max-width: fit-content;
  background-color: #0E2359;
  padding: 0.3rem 2rem;
  border-radius: 35px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out;

  a {
    color: #ffffff;
    text-decoration: none;
  }

  &:hover {
    background-color: #7F5CA5;
  }
}

.swalCancelBtn {
  max-width: fit-content;
  background-color: #706F6F;
  padding: 0.3rem 2rem;
  border-radius: 35px;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  transition: background-color 0.3s ease-in-out;

  a {
    color: #ffffff;
    text-decoration: none;
  }

  &:hover {
    background-color: #4c4c4c;
  }
}

#tabview-frequency-suscription {
  padding-top: 1rem;

  .p-tabview .p-tabview-panels {
    background: transparent;
    padding: 0rem 1rem 0rem 1rem;
    border: 0 none;
    color: #495057;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .p-tabview-nav-content {
    display: inline-flex;
  }

  .p-tabview .p-tabview-nav {
    background: #150E59;
    border: 2px solid #150E59;
    margin: 0rem 0rem 0rem 0rem;
    padding: 4px 4px 6px 4px;
    border-radius: 9999px;
    justify-content: center;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
    border-color: #ffffff;
    background: #ffffff;
    border-color: white;
    color: #5656A2;
    font-weight: bold;
    border-radius: 9999px;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #dee2e6;
    border-width: 0;
    border-color: none;
    background: transparent;
    color: #ffffff;
    padding: 1rem;
    font-weight: 600;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }
}

#breadcrumb {
  .p-breadcrumb {
    background: none;
    border: none;
    font-size: 26px;
    font-weight: 700;
    color: #1f2027;
    font-family: 'Roboto';
    text-align: center;

    ol {
      margin-bottom: 0rem;
      padding-left: 0rem;
    }

    ol li.p-breadcrumb-chevron {
      font-size: 20px;
      margin: 0 0.5rem 0 0.5rem;
      color: #7F5CA5;
    } 

    ol>li>a {
      text-decoration: none !important;

      span {
        color: #0E2359;
        transition: color 0.3s ease-in-out;
  
        &:hover {
          color: #7F5CA5;
        }
      }
    }

    ol li .p-menuitem-link .p-menuitem-icon {
      display: none;
    }
  }
}

// Casos de uso
.use-cases-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }

  .use-cases-items {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    .use-case-card {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      background-color: #fff;
      width: 25rem;
      height: 16rem;
      color: #000000;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      padding: 1rem 1rem 0rem 1rem;
      transition: transform 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.05);
      }

      .overlay-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 22rem;
        height: 100%;
        background-color: transparent;
        z-index: 2;
      }

      .use-case-content {
        width: 100%;
      }

      .use-case-actions {
        display: flex;
        gap: 0.4rem;
        justify-content: flex-end;
        margin-top: 3rem;

        .button {
          background-color: #150E59;
          color: #FFFFFF;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          cursor: pointer;
          text-align: center;
          z-index: 4;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #7F5CA5;
          }

          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }
}

.titulo-use-cases {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.new-use-case {
  .new-button {
    display: block;
    margin: 0 auto;
    text-align: center;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    background-color: #150E59;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #7F5CA5;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  label {
    margin-top: 0.5rem;
    font-weight: normal;
    cursor: pointer;
  }
}

// Flujos
.new-flow {
  .new-button {
    display: block;
    margin: 0 auto;
    text-align: center;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    background-color: #150E59;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #7F5CA5;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  label {
    margin-top: 0.5rem;
    font-weight: normal;
    cursor: pointer;
  }
}

.dropdown-modal-info-tributaria {
  .p-dropdown {
    width: 90%;
  }
}

// Estilos para el componente de DriverJS tutorial guiado
.disable-interaction {
  pointer-events: none;
  user-select: none;
}

.driver-popover.driverjs-theme {
  background-color: #42ac35;
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #ffffff;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #000;
  color: #ffffff;
  border: 1px solid #000;
  text-shadow: none;
  font-size: 14px;
  margin: 5px;
  border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
  background-color: #000;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
}

.driver-popover-navigation-btns {
  display: flex;
  flex-grow: unset !important;
  justify-content: flex-end;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  color: #9b9b9b;
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  color: #000;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #42ac35;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #42ac35;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #42ac35;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #42ac35;
}

.form-check-input:checked {
  background-color: #7F5CA5 !important;
  border-color: #7F5CA5 !important;
}